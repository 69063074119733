'use client';

import { FC, useContext, useEffect } from 'react';

import { getCountryCode, RibbonContext, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';

import { Entry } from 'contentful';

interface Props {
  locale: string;
  contentType: string | undefined;
  contentEntity: Entry<unknown> | undefined;
}

const ProductRibbon: FC<Props> = ({ contentType, contentEntity, locale }) => {
  const translate = useTranslations();
  const { dispatch } = useContext(RibbonContext);

  useEffect(() => {
    if (contentType !== 'product' || !contentEntity) return;

    const country = getCountryCode(locale);
    const isoCodes = (contentEntity as Entry<any>).fields.brand.fields?.countries?.map((productCountry: Entry<any>) => productCountry.fields.isoCode);

    if (!isoCodes?.includes(country)) {
      dispatch({ type: 'show_ribbon', value: { variant: 'error', message: translate('page-consumer.product-detail-alert'), closable: true } });
    }
  }, []);

  return null;
};

export default ProductRibbon;
