export interface RibbonState {
   ribbon?: {
      variant: 'neutral' | 'success' | 'warning' | 'error' | 'info';
      message: string;
      closable: boolean;
   };
}

export interface ShowRibbonAction {
   type: 'show_ribbon';
   value: { variant: 'neutral' | 'success' | 'warning' | 'error' | 'info'; message: string; closable: boolean };
}

export interface HideRibbonAction {
   type: 'hide_ribbon';
}

export type RibbonStateAction = ShowRibbonAction | HideRibbonAction;

export const initialState: RibbonState = {
   ribbon: undefined
};

export const RibbonReducer = (state: RibbonState, action: RibbonStateAction) => {
   switch (action.type) {
      case 'show_ribbon':
         return { ...state, ribbon: action.value };
      case 'hide_ribbon':
         return { ...state, ribbon: undefined };
      default:
         return state;
   }
};
