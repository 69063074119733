'use client';

import { useContext, useEffect } from 'react';

import { getCountryCode, RibbonContext, useTranslations } from '@ravago/shared/page-elements-radiance-consumer';

import { Entry } from 'contentful';

interface Props {
  locale: string;
  contentType: string | undefined;
  contentEntity: Entry<unknown> | undefined;
}

const BrandRibbon = ({ contentType, contentEntity, locale }: Props) => {
  const translate = useTranslations();
  const { dispatch } = useContext(RibbonContext);

  useEffect(() => {
    if (!contentEntity || contentType !== 'brand') return;

    const country = getCountryCode(locale);
    const isoCodes = (contentEntity as Entry<any>).fields.countries?.map((brandCountry: Entry<any>) => brandCountry.fields.isoCode);

    if (!isoCodes?.includes(country)) {
      dispatch({ type: 'show_ribbon', value: { variant: 'error', message: translate('page-consumer.brand-detail-alert'), closable: true } });
    }
  }, []);

  return null;
};

export default BrandRibbon;
