'use client';

import { createContext, Dispatch, FC, PropsWithChildren, useMemo, useReducer } from 'react';

import { initialState, RibbonReducer, RibbonState, RibbonStateAction } from '../reducers/ribbon-reducer';

export const RibbonContext = createContext<{ state: RibbonState; dispatch: Dispatch<RibbonStateAction> }>({
   state: initialState,
   dispatch: () => {}
});

export const RibbonProviders: FC<PropsWithChildren> = ({ children }) => {
   const [state, dispatch] = useReducer(RibbonReducer, initialState);

   const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

   return <RibbonContext.Provider value={contextValue}>{children}</RibbonContext.Provider>;
};
