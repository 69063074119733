import(/* webpackMode: "eager", webpackExports: ["capitalize"] */ "__barrel_optimize__?names=capitalize!=!/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/@mui/material/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/apps/next-page-consumer/app/lib/components/base/brand-ribbon/brand-ribbon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/apps/next-page-consumer/app/lib/components/base/product-ribbon/product-ribbon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useAuthenticationContext","PageProducerAuthenticationContextProvider","PreviewClientAuthenticationContextProvider"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/authentication/src/lib/contexts/authentication-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useAuthentication"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/authentication/src/lib/hooks/use-authentication.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticationProviders"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/authentication/src/lib/providers/authentication-providers.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/containers/node-properties/node-properties.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/containers/page-renderer-providers/page-render-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AddressList"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/address-list/components/address-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BrandHeaderElement"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/brand-header/brand-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BrandList"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/brand-list/components/brand-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BrandPortfolioListContentButton"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/brand-portfolio-list/components/brand-portfolio-list-content-button.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/brand-portfolio-list/components/brand-portfolio-list-content.module.scss");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/brand-portfolio-list/components/brand-portfolio-list-item.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["CallOffOrderDetails"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/call-off-order-details/components/call-off-order-details.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CaseStudyFeaturesListElement"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/case-study-features-list/case-study-features-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CaseStudyInformationElement"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/case-study-information/case-study-information.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CaseStudyListElementServer"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/case-study-list/case-study-list-server.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CaseStudyWarningBadgeElement"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/case-study-warning-badge/case-study-warning-badge.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CaseStudyWebsiteUrlElement"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/case-study-website-url/case-study-website-url.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DetailHeader"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/detail-header/components/detail-header.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/dynamic-page-link/dynamic-page-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EmailUnsubscribeError"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/email-unsubscribe/components/email-unsubscribe-error.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EmailUnsubscribeWrapper"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/email-unsubscribe/components/email-unsubscribe-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FormEmbedElement"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/form-embed/form-embed.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomepageSearch"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/homepage-search/components/homepage-search.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ImageGalleryElement"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/image-gallery/image-gallery.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InvoicesWithContext"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/invoices/components/invoices.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MarketItemList"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/market-item-list-view/components/market-item-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MarketKeyAreas"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/market-key-areas/components/market-key-areas.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/mock-link/mock-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OrderDetails"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/order-details/components/order-details.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OrderOverview"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/order-overview/components/order-overview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProducerHeaderElement"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/producer-header/producer-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProducerList"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/producer-list/components/producer-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductActions"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/product-actions/components/product-actions.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/product-card/product-card.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["ProductCustomizableTable"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/product-customizable-table/components/product-customizable-table.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductHeaderElement"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/product-header/product-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductList"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/product-list/components/product-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductListerBlock"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/product-lister-block/components/product-lister-block.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductPortfolio"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/product-portfolio/components/product-portfolio.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/product-recommendation-badge/product-recommendation-badge.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QuickTdsLink"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/quick-tds-link/components/quick-tds-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RegistrationNumberList"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/registration-number-list/components/registration-number-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RelatedDocumentList"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/related-documents/components/related-document-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RequestForQuotation"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/request-for-quotation/components/request-for-quotation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RequestMissingDocumentsButton"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/request-missing-documents-button/components/request-missing-documents-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShipmentDetails"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/shipment-details/components/shipment-details.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TechnologyItemList"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/technology-item-list-view/components/technology-item-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TextAccordionElement"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/text-accordion/text-accordion.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/text/text-element.css");
;
import(/* webpackMode: "eager", webpackExports: ["VatNumberList"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/vat-number-list/components/vat-number-list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VideoElement"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/content/video/video.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/shared/auth-guard/auth-guard.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/shared/blog-preview/blog-preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Breadcrumbs"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/shared/breadcrumbs/breadcrumbs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductCard"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/shared/cards/product-card/product-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MarketSegmentContent"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/shared/market-segment/market-segment-content.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/shared/placeholder-text/placeholder-text.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductSpecifications"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/components/shared/product-specifications/product-specifications.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useTranslations"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/providers/translation-client-context-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CorrelationService","generateCorrelationId"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/page-elements-radiance/src/lib/services/tracking/correlation-token.service.ts");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/radiance/src/lib/components/button/components/Button/Button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IconButton"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/radiance/src/lib/components/button/components/IconButton/IconButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MarketCard"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/radiance/src/lib/components/card/components/MarketCard/MarketCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/libs/shared/radiance/src/lib/components/carousel/components/Carousel/Carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/ravago-digital-platform/ravago-digital-platform/node_modules/next/dist/client/link.js");
